import React from "react"
import styled from "styled-components"
import { Bold, flexCenter } from "../structure/theme/styles.component"
import { ButtonLink } from "../structure/theme/button.component"
import { colors, fontSizes } from "../structure/theme"
import { createSelfCareLink } from "../navigation/navigation.utils"
import breakpoint from "styled-components-breakpoint"
import PropTypes from "prop-types"
import { Trans, useTranslation } from "../translation/translate.component"
import usePhoneCostNumber from "../contact/phoneCostNumber.hook"

const Wrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  margin: 24px 0;
`

const Help = styled.div`
  padding: 20px;
  background-color: ${colors(`grey.g400`)};
  width: 100%;

  ${breakpoint(`small`)`
  padding: 24px;
  `}
`

const HelpText = styled.div`
  display: block;
  text-align: center;

  ${breakpoint(`small`)`
    ${flexCenter};
  `}
`

const HelpNotice = styled.div`
  ${flexCenter};
  font-size: ${fontSizes(`small`)};
`

const Red = styled.div`
  color: ${colors(`primary`)};
  font-weight: 900;
  margin-bottom: 8px;
`

const H4 = styled.h4`
  color: ${colors(`secondary`)};
  text-align: center;
  font-size: ${fontSizes(`x_large`)};
  margin-bottom: 8px;
  font-weight: 900;
`

export default function EligibilityModal ({ title, error }) {
  const { t } = useTranslation()
  const phoneCostNumber = usePhoneCostNumber()

  return (
    <Wrapper flexDirection="column" data-testid="eligibility_modal">
      <H4 data-testid="eligibility_modal_title">{title}</H4>
      <div data-testid="eligibility_modal_error">{error}</div>
      <Red>{t(`common:payment.modal_eligibility_error`)}</Red>
      <Bold>{t(`common:payment.modal_eligibility_error_text`)}</Bold>
      {APP_CONFIG.featureFlags.selfcare && (
        <ButtonWrapper>
          <ButtonLink target="__blank" href={createSelfCareLink(APP_CONFIG.selfcare.login, `PopinNonEligibilite`)}>
            {t(`common:payment.modal_eligibility_link_client_area`)}
          </ButtonLink>
        </ButtonWrapper>
      )}
      <Help>
        <HelpText>
          <span>
            <Trans t={t} i18nKey="common:payment.modal_eligibility_help_text" />
          </span>
          <Bold>
            <Trans
              t={t}
              i18nKey="common:payment.modal_eligibility_help_text"
              values={{ serviceNumber: APP_CONFIG.serviceNumber }}
            />
          </Bold>
        </HelpText>
        {phoneCostNumber && <HelpNotice>({phoneCostNumber})</HelpNotice>}
      </Help>
    </Wrapper>
  )
}

EligibilityModal.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
}
